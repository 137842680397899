@font-face {
  font-family: "Circular Std";
  src: url("./../fonts/CircularStdBold.eot");
  src: local("☺"), url("./../fonts/Circular Std Font.otf") format("otf"),
    url("./../fonts/CircularStdBold.woff") format("woff"),
    url("./../fonts/CircularStdBold.ttf") format("truetype");
}
@font-face {
  font-family: "Circular Std Book";
  src: url("./../fonts/CircularStdBold.eot");
  src: local("☺"), url("./../fonts/circularstd-book.otf") format("otf"),
    url("./../fonts/CircularStdBold.woff") format("woff"),
    url("./../fonts/CircularStdBold.ttf") format("truetype");
}
@font-face {
  font-family: "Circular Std-Medium";
  src: url("./../fonts/CircularStdBold.eot");
  src: local("☺"), url("./../fonts/circularstd-book.otf") format("otf"),
    url("./../fonts/CircularStdBold.woff") format("woff"),
    url("./../fonts/CircularStdBold.ttf") format("truetype");
}
@font-face {
  font-family: "Heebo";
  src: local("☺"), url("./../fonts/Heebo-Regular.ttf") format("truetype");
}
