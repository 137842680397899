.header {
  background: #ffffff;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.02);
  position: fixed !important;
  width: 100vw;
  overflow-x: hidden;
  z-index: 1000;

  .button-header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px 32px;
    background: linear-gradient(
        0deg,
        rgba(29, 26, 26, 0.12),
        rgba(29, 26, 26, 0.12)
      ),
      #74dae0;
    border-radius: 60px;
    border: none;
    width: 145px;
    height: 56px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
    position: absolute;
    left: calc(100vw - 177px);
    &:hover {
      text-decoration: none;
    }
    @media (max-width: 760px) {
      left: calc(100vw - 153px);
      width: 129px;
      height: 40px;
      padding: 8px 24px;
    }
  }

  .navbar {
    padding: 30px 40px 20px 40px !important;
    @media (max-width: 760px) {
      padding: 15px 20px 15px 20px !important;
    }
  }

  .navbarwithoutBtn {
    justify-content: center !important;
  }
}
