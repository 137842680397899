.contatctUsMain {
  display: flex;
  justify-content: center;
  padding-top: 106px;

  .contactUs-grid {
    width: 100%;
    max-width: 680px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: 760px) {
      width: 95vw;
    }
    .page-title-text {
      font-family: Circular Std;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 52px;
      color: #292d39;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin-top: 54px;
    }
    .description {
      margin-top: 24px;
      .page-description {
        font-family: Heebo;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 32px;
        letter-spacing: 0.02em;
        color: #878a90;
        flex: none;
        order: 1;
        flex-grow: 0;

        .description-email {
          color: #6dcbd0;
        }
      }
    }
  }

  .contactForm-box {
    margin-top: 48px;
    margin-bottom: 14px;
    width: 100%;
    @media (max-width: 760px) {
      width: 95vw;
    }
    .form-title {
      font-family: Circular Std;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 32px;
      color: #292d39;
      flex: none;
      order: 0;
      flex-grow: 0;
    }
    .main-form {
      margin-top: 32px;

      .contactForm-form-group {
        margin-bottom: 27px;

        .contactForm-lable {
          font-family: Circular Std;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 28px;
          letter-spacing: 0.02em;
          color: #292d39;
        }

        .contactForm-InputText {
          width: 100%;
          height: 48px;
          background: #ffffff;
          border: 1px solid rgba(41, 45, 57, 0.12);
          box-sizing: border-box;
          border-radius: 4px;
          text-indent: 10px;
          margin-top: 8px;
        }
        .contactForm-InputText:focus {
          border: 1px solid #6dcbd0;
          opacity: 1;
        }

        .contactForm-InputTextErea {
          width: 100%;
          height: 120px;
          background: #ffffff;
          border: 1px solid rgba(41, 45, 57, 0.12);
          box-sizing: border-box;
          border-radius: 4px;
          text-indent: 10px;
          margin-top: 8px;
          &:focus {
            border: 1px solid #6dcbd0;
            opacity: 1;
          }
        }
      }
    }

    .button-box {
      display: flex;
      justify-content: flex-end;

      .contactForm-sendBtn {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 16px 32px;
        width: 107px;
        height: 60px;
        background: linear-gradient(
            0deg,
            rgba(29, 26, 26, 0.12),
            rgba(29, 26, 26, 0.12)
          ),
          #74dae0;
        border-radius: 60px;
        flex: none;
        order: 6;
        flex-grow: 0;
        border: 0px;
        font-family: Circular Std;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #ffffff;
      }
    }
  }
}
