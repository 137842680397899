.first-view-text {
  width: 580px;
  height: 104px;
  font-family: Circular Std;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 52px;
  text-align: center;
  color: #292d39;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-top: 90px;
  @media (max-width: 768px) {
    margin-top: 16px;
    width: 90vw;
    height: auto;
    font-size: 28px;
    line-height: 40px;
  }
}

.first-view {
  margin-top: -10px;
  padding-top: 106px;
  background: linear-gradient(
    180deg,
    rgba(243, 248, 249, 0.4) 0%,
    #f3f8f9 100%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 70px);
  min-height: 1024px;
  position: relative;
  @media (max-width: 768px) {
    padding-top: 76px;
    min-height: 860px;
  }
  .box-title {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

.first-view-description {
  height: 64px;
  width: 580px;
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #292d39;
  opacity: 0.6;
}

.first-view-MaximeCard {
  position: absolute !important;
  width: 240px;
  height: 318px;
  opacity: 1;
  left: calc(100vw - 360px);
  //   right: 6%;
  top: 270px;
  background: #ffffff;
  box-shadow: 0px 48px 24px rgba(48, 76, 81, 0.08),
    0px 120px 120px rgba(48, 76, 81, 0.12);
  border-radius: 8px;
  z-index: 100;
  @media (max-width: 1024px) {
    top: 370px;
  }
}
.first-view-Golden-Brac-smallCard {
  position: absolute !important;
  width: 128px;
  height: 175px;
  //   right: 83%;
  left: 120px;
  top: 245px;
  background: #ffffff;
  box-shadow: 0px 12px 8px rgba(48, 76, 81, 0.04),
    0px 40px 40px rgba(48, 76, 81, 0.04);
  border-radius: 8px;
  z-index: 100;

  @media (max-width: 1024px) {
    top: 345px;
  }
}

.first-view-iphonesCard {
  position: absolute !important;
  width: 128px;
  height: 175px;
  //   right: 78%;
  left: 204px;
  top: 332px;

  background: #ffffff;
  box-shadow: 0px 12px 8px rgba(48, 76, 81, 0.04),
    0px 40px 40px rgba(48, 76, 81, 0.04);
  border-radius: 8px;
  @media (max-width: 1024px) {
    top: 432px;
  }
}
.first-view-Golden-BraceCard {
  position: absolute !important;
  width: 160px;
  height: 220px;
  //   left: 3%;
  left: 60px;
  top: 650px;

  background: #ffffff;
  box-shadow: 0px 24px 12px rgba(48, 76, 81, 0.04),
    0px 64px 64px rgba(48, 76, 81, 0.08);
  border-radius: 8px;

  @media (max-width: 1024px) {
    top: 890px;
  }
}

.first-view-MacbookProCard {
  position: absolute !important;
  width: 128px;
  height: 175px;
  top: 550px;
  left: calc(100vw - 180px);
  background: #ffffff;
  box-shadow: 0px 12px 8px rgba(48, 76, 81, 0.04),
    0px 40px 40px rgba(48, 76, 81, 0.04);
  border-radius: 8px;
  @media (max-width: 1024px) {
    top: 650px;
  }
}

.demoSection-Untitled_Artwork84 {
  @media (max-width: 1024px) {
    display: none;
  }
}

.first-view-board {
  position: absolute;
  width: 90vw;
  max-width: 480px;
  max-height: 480px;
  bottom: 12px;
  img {
    width: 90vw;
    max-width: 480px;
  }
  @media (max-width: 480px) {
    bottom: 22px;
  }
}
.card {
  border: none !important;
}
.first-view-contactus-btn:hover {
  color: #fff;
  text-decoration: none;
  opacity: 0.9;
}
.first-view-contactus-btn {
  width: 157px;
  height: 60px;
  background: linear-gradient(
      0deg,
      rgba(29, 26, 26, 0.08),
      rgba(29, 26, 26, 0.08)
    ),
    #74dae0;
  border-radius: 44px;
  color: #ffffff;
  text-align: center;
  line-height: 45px;
  z-index: 100;
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.background-icon {
  width: 28px;
  height: 28px;
  background-color: #fbf8f7;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.howWork-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
  @media (max-width: 768px) {
    padding: 0px !important;
  }
}
.itemInHeader {
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #292d39;
  flex: none;
  order: 0;
  flex-grow: 0;
  &:hover {
    text-decoration: none;
  }
}

.first-view-description {
  @media (max-width: 350px) {
    margin-top: 25px;
  }
  @media (max-width: 300px) {
    margin-top: 45px;
  }
}

.yourStore-box-button {
  width: 347px;
  height: 56px;
  color: #ffffff;
  background: linear-gradient(
      0deg,
      rgba(29, 26, 26, 0.08),
      rgba(29, 26, 26, 0.08)
    ),
    #74dae0;
  border-radius: 8px;
  border: 1px solid #74dae0;
  cursor: default !important;
  @media (max-width: 768px) {
    width: 295px;
    height: 72px;
  }
  span {
    font-family: Heebo;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    letter-spacing: 0.02em;
  }
}
.yourStore-box-header-icon {
  &:first-child {
    @media (max-width: 768px) {
      display: none;
    }
  }
}

.yourStore-box-header-card-group {
  display: flex;
  flex-direction: row;
  // flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 80px;

  @media (max-width: 768px) {
    margin-top: 16px;
    max-width: 96vw;
    overflow: scroll;
    white-space: nowrap;
    justify-content: flex-start;
    padding: 0px 16px;
    &:last-child {
      margin-right: 150px;
    }
  }
}

.yourStore-box-header-card {
  min-width: 176px !important;
  max-width: 176px;
  margin-right: 12px;
}

.yourStore-box-button-position {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;

  @media (max-width: 768px) {
    margin-top: 16px;
  }
}

.solution {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 160px;
  @media (max-width: 768px) {
    margin-top: 113px;
  }
}
.solution-item-group {
  @media (max-width: 768px) {
    margin-top: 48px;
  }
}

.howWork-item-group-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  @media (max-width: 768px) {
    margin-top: 24px;
  }
}

.footer {
  min-height: 136px;
  /* width: 100%;
  display: flex;
  flex-direction: row; */
  /* margin-bottom: 20px; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    min-height: 88px;
  }
}

.demoSection {
  min-height: 434px;
  position: relative;
  margin-top: 100px;
  background: #f3f8f9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    min-height: 308px;
  }
}

.demoSection-title {
  font-family: Circular Std;
  font-style: normal;
  font-size: 36px;
  line-height: 52px;
  text-align: center;
  letter-spacing: -0.02em;
  margin-top: 0px;
  font-weight: bold;
  color: #292d39;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.first-view-Untitled_Artwork831 {
  @media (max-width: 768px) {
    left: 2%;
    top: 72%;
  }
}
.first-view-Untitled_Artwork841 {
  @media (max-width: 768px) {
    left: 2%;
    top: 6%;
  }
}
.first-view-Untitled_Artwork851 {
  @media (max-width: 768px) {
    left: 85%;
    top: 72%;
  }
}
.first-view-Untitled_Artwork861 {
  @media (max-width: 768px) {
    left: 85%;
    top: 6%;
  }
}

.insights-column-right-image1 {
  @media (max-width: 1024px) {
    width: 465px;
    height: auto;
  }
  @media (max-width: 834px) {
    width: 392px;
    height: auto;
  }
  @media (max-width: 768px) {
    width: 100vw;
    height: auto;
  }
}

.first-view-Untitled_Artwork83 {
  @media (max-width: 1024px) {
    left: 26%;
    top: 72%;
  }
}
.first-view-Untitled_Artwork84 {
  @media (max-width: 1024px) {
    left: 37%;
    top: 61%;
  }
}
.first-view-Untitled_Artwork85 {
  @media (max-width: 1024px) {
    left: 72%;
    top: 72%;
  }
}
.first-view-Untitled_Artwork86 {
  @media (max-width: 1024px) {
    left: 60%;
    top: 59%;
  }
}
